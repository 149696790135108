import React, { Component } from 'react';

import queryString from 'query-string';
import axios from 'axios';

import { PropTypes } from 'prop-types';

import HomeComponent from '../components/HomeComponent';

class HomeContainer extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            rooms: []
        };
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        
        if (!values.keys) {
            return;
        }

        let keys = values.keys.split(',');
        
        axios.get('https://554313.s.dedikuoti.lt:5443/rooms').then((response) => {
            let rooms = [];
            for (let i in response.data) {
                for (let j in keys) {
                    if (response.data[i].key === keys[j]) {
                        rooms.push(response.data[i]);
                    }
                }
            }

            this.setState({
                rooms: rooms
            });
        });
    }

    render() {
        return (
            <HomeComponent
                rooms={this.state.rooms}
            />
        );
    }
}

HomeContainer.contextTypes = {
    router: PropTypes.object
};

export default HomeContainer;
