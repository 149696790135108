import React from 'react';
import Sound from 'react-sound';

class SoundComponent extends React.Component {
    render() {
        return <Sound {...this.props}></Sound>;
    }
}

export default SoundComponent;
