import React from 'react';
import { Link } from 'react-router-dom';

import { PropTypes } from 'prop-types';

const HomeComponent = (props) => {

    let roomsList = props.rooms.map((room, index) => {
        return <Link className="primary-button" key={index} to={'/r/' + room.tag} target="_blank">{room.name}</Link>;
    });

    return <div className="home">
        <div>
            <h1 itemProp="headline">Prieinami kambariai</h1>
            <table>
                <tbody>{roomsList}</tbody>
            </table>
        </div>
    </div>;
}

HomeComponent.propTypes = {
    rooms: PropTypes.array.isRequired
};

export default HomeComponent;
