import React, { Component } from 'react';
import { connect } from 'react-redux';

import io from 'socket.io-client';

import store from '../store';

import MediaContainer from './MediaContainer';
import CommunicationContainer from './CommunicationContainer';

const mapStateToProps = (store) => ({
    rooms: new Set([...store.rooms])
});

const mapDispatchToProps = (dispatch, ownProps) => (
    {
        addRoom: () => {
            store.dispatch({
                type: 'ADD_ROOM', room: ownProps.match.params.room
            })
        }
    }
);

class RoomContainer extends Component {
    constructor(props) {
        super(props);

        this.getUserMedia = navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        }).catch((e) => {
            alert('getUserMedia() error: ' + e.name);
        })

        this.socket = io('https://554313.s.dedikuoti.lt:5443');
        //this.socket = io('http://localhost:5443');
    }

    componentDidMount() {
        this.props.addRoom();
    }

    render() {
        return (
            <div>
                <MediaContainer media={media => this.media = media} socket={this.socket} getUserMedia={this.getUserMedia} />
                <CommunicationContainer socket={this.socket} media={this.media} getUserMedia={this.getUserMedia} />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomContainer);
