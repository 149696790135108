import React, { Component } from 'react';

import { PropTypes } from 'prop-types';

class MediaContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bridge: '',
            user: ''
        }

        this.onRemoteHangup = this.onRemoteHangup.bind(this);
        this.onMessage = this.onMessage.bind(this);
        this.sendData = this.sendData.bind(this);
        this.setupDataHandlers = this.setupDataHandlers.bind(this);
        this.setDescription = this.setDescription.bind(this);
        this.sendDescription = this.sendDescription.bind(this);
        this.hangup = this.hangup.bind(this);
        this.init = this.init.bind(this);
        this.setDescription = this.setDescription.bind(this);

        this.interval = null;
    }

    componentWillMount() {
        // chrome polyfill for connection between the local device and a remote peer
        window.RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection;
        this.props.media(this);
    }

    componentDidMount() {
        this.props.getUserMedia
            .then((stream) => {
                this.localVideo.srcObject = this.localStream = stream
            });

        this.props.socket.on('message', this.onMessage);
        this.props.socket.on('hangup', this.onRemoteHangup);
    }

    componentWillUnmount() {
        this.props.media(null);

        if (this.localStream) {
            if (this.localStream.getVideoTracks().length > 0) {
                this.localStream.getVideoTracks()[0].stop();
            }

            if (this.localStream.getAudioTracks().length > 0) {
                this.localStream.getAudioTracks()[0].stop();
            }
        }

        this.props.socket.emit('leave');
        //this.hangup();
    }

    onRemoteHangup() {
        this.setState({
            user: 'host',
            bridge: 'host-hangup'
        });
    }

    onMessage(message) {
        console.log('msg:', message);

        if (message.type === 'on-connected') {
            this.props.socket.emit('rooms');
        } else if (message.type === 'offer') {
            // set remote description and answer
            this.pc.setRemoteDescription(new RTCSessionDescription(message));
            this.pc.createAnswer()
                .then(this.setDescription)
                .then(this.sendDescription)
                .catch(this.handleError); // An error occurred, so handle the failure to connect

        } else if (message.type === 'answer') {
            // set remote description
            this.pc.setRemoteDescription(new RTCSessionDescription(message));
        } else if (message.type === 'candidate') {
            // add ice candidate
            this.pc.addIceCandidate(
                new RTCIceCandidate({
                    sdpMLineIndex: message.mlineindex,
                    candidate: message.candidate
                })
            );
        }
    }

    sendData(msg) {
        this.dc.send(JSON.stringify(msg));
    }

    // Set up the data channel message handler
    setupDataHandlers() {
        this.dc.onmessage = (e) => {
            var msg = JSON.parse(e.data);
            console.log('received message over data channel:' + JSON.stringify(msg));
        };

        this.dc.onclose = () => {
            if (this.remoteStream.getVideoTracks().length > 0) {
                this.remoteStream.getVideoTracks()[0].stop();
            }
            
            if (this.remoteStream.getAudioTracks().length > 0) {
                this.remoteStream.getAudioTracks()[0].stop();
            }

            console.log('The Data Channel is Closed');
        };
    }

    setDescription(offer) {
        this.pc.setLocalDescription(offer);
    }

    // send the offer to a server to be forwarded to the other peer
    sendDescription() {
        this.props.socket.send(this.pc.localDescription);
    }

    restart() {
        if (this.state.user !== 'host') {
            return;
        }

        if (!this.pc) {
            return;
        }
        
        console.log('restart');

        this.pc.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: false,
            iceRestart: true
        })
            .then(this.setDescription)
            .then(this.sendDescription)
            .catch(this.handleError); // An error occurred, so handle the failure to connect
    }

    hangup() {
        if (this.interval) {
            clearInterval(this.interval);
        }

        this.setState({
            user: 'guest',
            bridge: 'guest-hangup'
        });

        this.pc.close();
        this.props.socket.emit('leave');
    }

    handleError(e) {
        console.log(e);
    }

    init() {
        // wait for local media to be ready
        const attachMediaIfReady = () => {
            this.dc = this.pc.createDataChannel('chat');
            this.setupDataHandlers();
            
            this.pc.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: false
            })
                .then(this.setDescription)
                .then(this.sendDescription)
                .then(() => {
                    this.interval = setInterval(() => {
                        this.restart();
                    }, 15 * 60 * 1000);
                })
                .catch(this.handleError); // An error occurred, so handle the failure to connect
        }

        // set up the peer connection
        // this is one of Google's public STUN servers
        // make sure your offer/answer role does not change. If user A does a SLD
        // with type=offer initially, it must do that during  the whole session
        this.pc = new RTCPeerConnection({
            iceServers: [
                {
                    urls: 'turn:554313.s.dedikuoti.lt:3478',
                    username: 'user',
                    credential: 'temp123456'
                },
                {
                    url: 'stun:stun.l.google.com:19302'
                }
             ]
        });
        
        // when our browser gets a candidate, send it to the peer
        this.pc.onicecandidate = (e) => {
            if (e.candidate) {
                this.props.socket.send({
                    type: 'candidate',
                    mlineindex: e.candidate.sdpMLineIndex,
                    candidate: e.candidate.candidate
                });
            }
        };

        // when the other side added a media stream, show it on screen
        this.pc.onaddstream = (e) => {
            this.remoteStream = e.stream;
            this.remoteVideo.srcObject = this.remoteStream = e.stream;
            this.setState({
                bridge: 'established'
            });
        };
        
        this.pc.oniceconnectionstatechange = (e) => {
            switch (this.pc.iceConnectionState) {
                case 'disconnected':
                    this.onRemoteHangup();
                    break;
                default:
                    break;
            }
        };

        this.pc.ondatachannel = (e) => {
            // data channel
            this.dc = e.channel;
            this.setupDataHandlers();

            let videoEnabled = false;
            if (this.localStream.getVideoTracks().length > 0) {
                videoEnabled = this.localStream.getVideoTracks()[0].enabled;
            }

            this.sendData({
                peerMediaStream: {
                    video: videoEnabled
                }
            });
            //sendData('hello');
        };

        // attach local media to the peer connection
        this.localStream.getTracks().forEach((track) => {
            this.pc.addTrack(track, this.localStream);
        });

        // call if we were the last to connect (to increase
        // chances that everything is set up properly at both ends)
        if (this.state.user === 'host') {
            this.props.getUserMedia.then(attachMediaIfReady);
        }
    }

    render() {
        return (
            <div className={`media-bridge ${this.state.bridge}`}>
                <video className="remote-video" ref={(ref) => this.remoteVideo = ref} autoPlay></video>
                <video className="local-video" ref={(ref) => this.localVideo = ref} autoPlay muted></video>
            </div>
        );
    }
}

MediaContainer.propTypes = {
    socket: PropTypes.object.isRequired,
    getUserMedia: PropTypes.object.isRequired,
    media: PropTypes.func.isRequired
}

export default MediaContainer;
