import { combineReducers } from 'redux';

// Reducers
import roomReducer from './room-reducer';
import audioReducer from './audio-reducer';
import videoReducer from './video-reducer';

// Combine Reducers
const reducers = combineReducers({
    rooms: roomReducer,
    video: videoReducer,
    audio: audioReducer
});

export default reducers;