import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PropTypes } from 'prop-types';
import Remarkable from 'remarkable';
import * as $ from 'jquery';

import store from '../store';

import CommunicationComponent from '../components/CommunicationComponent';

import MediaContainer from './MediaContainer';

import Sound from 'react-sound';

const mapStateToProps = (store) => ({
    video: store.video,
    audio: store.audio
});

const mapDispatchToProps = (dispatch) => ({
    setVideo: (boo) => {
        store.dispatch({
            type: 'SET_VIDEO',
            video: boo
        });
    },
    setAudio: (boo) => {
        store.dispatch({
            type: 'SET_AUDIO',
            audio: boo
        });
    }
});

class CommunicationContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sid: '',
            message: '',
            audio: true,
            video: false,
            playStatus: Sound.status.STOPPED
        };

        this.handleInvitation = this.handleInvitation.bind(this);
        this.handleHangup = this.handleHangup.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.toggleVideo = this.toggleVideo.bind(this);
        this.toggleAudio = this.toggleAudio.bind(this);
        this.send = this.send.bind(this);
    }

    hideAuth() {
        this.props.media.setState({
            bridge: 'connecting'
        });

        $('#span-calling').css('display', 'none');
    }

    full() {
        console.log('full');
        if (this.props) {
            this.props.media.setState({
                bridge: 'full'
            });
        }
    }

    componentDidMount() {
        const socket = this.props.socket;
        
        this.setState({
            video: this.props.video,
            audio: this.props.audio
        });

        socket.on('create', () => {
            this.props.media.setState({
                user: 'host',
                bridge: 'create'
            });
        });

        socket.on('full', this.full);
        
        socket.on('bridge', (role) => {
            console.log('bridge');
            this.props.media.init();
        });
        
        socket.on('join', () => {
            console.log('join');

            this.props.media.setState({
                user: 'guest',
                bridge: 'join'
            });
        });
        
        socket.on('approve', ({ message, sid }) => {
            this.setState({
                playStatus: Sound.status.PLAYING
            });
            console.log('approve');
            this.props.media.setState({
                bridge: 'approve'
            });
            
            this.setState({ message, sid });
        });

        socket.emit('find');

        this.props.getUserMedia
            .then((stream) => {
                this.localStream = stream;

                if (this.localStream.getAudioTracks().length > 0) {
                    this.localStream.getAudioTracks()[0].enabled = this.state.audio;
                }

                if (this.localStream.getVideoTracks().length > 0) {
                    this.localStream.getVideoTracks()[0].enabled = this.state.audio;
                }
            });
    }

    handleInput(e) {
        this.setState({
            [e.target.dataset.ref]: e.target.value
        });
    }

    send(e) {
        e.preventDefault();
        this.props.socket.emit('auth', this.state);
        //this.hideAuth();

        $('#span-calling').css('display', 'initial');
    }

    handleInvitation(e) {
        this.setState({
            playStatus: Sound.status.STOPPED
        });
        console.log('handle invitation');
        e.preventDefault();
        this.props.socket.emit([e.target.dataset.ref], this.state.sid);
        this.hideAuth();
    }

    getContent(content) {
        return {
            __html: (new Remarkable()).render(content)
        };
    }

    toggleVideo() {
        if (this.localStream.getVideoTracks().length <= 0) {
            console.warn('No A/V devices available.');
            return;
        }

        const video = this.localStream.getVideoTracks()[0].enabled = !this.state.video;
        this.setState({ video: video });
        this.props.setVideo(video);
    }

    toggleAudio() {
        if (this.localStream.getAudioTracks().length <= 0) {
            console.warn('No A/V devices available.');
            return;
        }
        
        const audio = this.localStream.getAudioTracks()[0].enabled = !this.state.audio;
        this.setState({
            audio: audio
        });
        this.props.setAudio(audio);
    }

    handleHangup() {
        this.props.media.hangup();
        $('#span-calling').css('display', 'none');
    }

    render() {
        return (
            <CommunicationComponent
                {...this.state}
                toggleVideo={this.toggleVideo}
                toggleAudio={this.toggleAudio}
                getContent={this.getContent}
                send={this.send}
                handleHangup={this.handleHangup}
                handleInput={this.handleInput}
                handleInvitation={this.handleInvitation}>
            </CommunicationComponent>
        );
    }
}

CommunicationContainer.propTypes = {
    socket: PropTypes.object.isRequired,
    getUserMedia: PropTypes.object.isRequired,
    audio: PropTypes.bool.isRequired,
    video: PropTypes.bool.isRequired,
    setVideo: PropTypes.func.isRequired,
    setAudio: PropTypes.func.isRequired,
    media: PropTypes.instanceOf(MediaContainer)
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationContainer);
