import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import store from './store';
import './index.css';

import HomeContainer from './containers/HomeContainer';
import RoomContainer from './containers/RoomContainer';
import NotFoundComponent from './components/NotFoundComponent';

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={HomeContainer} />
				<Route path="/r/:room" component={RoomContainer} />
				<Route path="*" component={NotFoundComponent} />
			</Switch>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
